import React, { useMemo } from 'react'
import { UsersUnion, UsersUnionMemberInvite, UsersUnionTransferRequest, useUsersUnionTransferAcceptMutation, useUsersUnionTransferRejectMutation, useUsersUnionTransferRequestsQuery } from '../../generated-types'
import TopAlertsList from '../Project/TopAlertsList'
import { Link } from '../Link'
import { useTranslation } from 'react-i18next'
import client, { pollInterval } from '../../ApolloClient'
import { message } from 'antd'
import { allUsersUnions } from '../../subscriptions/allUsersUnions'

function UsersUnionsTransfersList() {

    const { t } = useTranslation()
    const { data } = useUsersUnionTransferRequestsQuery({
        pollInterval: pollInterval * 2
    })
    const [accept] = useUsersUnionTransferAcceptMutation()
    const [reject] = useUsersUnionTransferRejectMutation()

    const acceptTransfer = (req: UsersUnionTransferRequest) => {
        accept({
            variables: {
                Id: req.id
            },
            onError(err) {
                message.error(err.message)
            },
            refetchQueries: ['UsersUnionTransferRequests'],
            onCompleted: () => {
                client.refetchQueries({
                    include: ["UsersUnionsList"],
                    optimistic: true,
                    onQueryUpdated: async (observableQuery, {  }) => {
                        const {data, error} =await observableQuery.refetch()
                        if(error)
                            return
                        
                        allUsersUnions({
                            unions: data.usersUnions as UsersUnion[] ?? [],
                            invites: data.usersUnionsInvites as UsersUnionMemberInvite[] ?? []
                        });
                    }
                })
            }
        })
    }

    const declineTransfer = (req: UsersUnionTransferRequest) => {
        reject({
            variables: {
                id: req.id
            },
            refetchQueries: ['UsersUnionTransferRequests']
        })
    }

    return (
        <TopAlertsList<UsersUnionTransferRequest>
            onRender={(i) => {
                return <div style={{ display: "flex" }}>
                    {t('usersUnion.transfer.newTransfer')} <Link style={{ padding: "0 4px" }} to={`/uutransfer/${i.linkId}`}>{i.usersUnion.title}</Link>
                </div>
            }}
            items={data?.usersUnionTransferRequests as UsersUnionTransferRequest[] ?? []}
            getUser={i => i.requester}
            acceptTitle={t('usersUnion.transfer.acceptTransfer')}
            declineTitle={t('usersUnion.transfer.declineTransfer')}
            declinePopupTitle={t('usersUnion.transfer.declineTransferTitle')}
            declinePopupText={(i) => t('usersUnion.transfer.declineTransferBody', { title: i.usersUnion.title })}
            onAccept={acceptTransfer}
            onDecline={declineTransfer}
        ></TopAlertsList>
    )
}

export default UsersUnionsTransfersList