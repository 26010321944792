import React, { useEffect, useMemo, useState } from 'react'
import { PaymentAccount, User, UsersUnion, useUserPaymentAccountQuery, useUsersUnionTransferCancelMutation, useUsersUnionTransferMutation } from '../../generated-types'
import styled from 'styled-components'
import { Link as lnk } from 'react-router-dom'
import UserAvatar from '../User/UserAvatar'
import { Icon } from '../Icon/Icon'
import { IconClock } from '../Icon/IconClock'
import { useTranslation } from 'react-i18next'
import { useReactiveVar } from '@apollo/client'
import { authState } from '../../routes/Auth/authContext'
import { IconArrowRight } from '../Icon/IconArrowRight'
import { IconArrowRightWithTail } from '../Icon/IconArrowRightWithTail'
import { Button, message, Popconfirm } from 'antd'
import TooltipButton from '../TooltipButton'
import { IconUserProject } from '../Icon/IconUserProject'
import { IconClose } from '../Icon/IconClose'
import Dropdowned, { CenterJustify } from '../Dropdowned'
import UserSearch, { UserOrUnionInvite } from '../User/UserSearch'
import { isMobileOnly } from 'react-device-detect'
import RefetchesList from '../../queries/RefetchesList'
import DropdownedPoptitle from '../DropdownedPoptitle'
import MyPopconfirm from '../MyPopconfirm'

interface UsersUnionTransferProps {
  union: UsersUnion
}

const UsersUnionTransferContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    >.uu-owners-container{
      display: flex;
      gap: 8px;
      align-items: center;

      >svg{
        opacity: 0.5;
      }

    }

    >.uu-transfer-in-progress-message-container{
      display: flex;
      gap: 8px;
      align-items: center;
      opacity: 0.5;
      
      >svg{
        flex-shrink: 0;
      }

      >span{
        font-size: 12px;
        max-width: 276px;
        line-height: 1;
        font-weight: 300;
      }
    }

    >.uu-transfer-buttons-container{
      display: flex;
      gap: 8px;
      font-weight: 300;
      
      >.ant-btn{

        padding: 10px 14px;
        align-items: center;

        >.ant-btn-icon{
        line-height: 0;
        }

        &.transfer-cancel-btn{
          color: ${p => p.theme.colors.font.accent};
          border-color: ${p => p.theme.colors.font.accent};

          &:hover{
            color: ${p => p.theme.colors.ui.accentHover};
            border-color: ${p => p.theme.colors.ui.accentHover};
          }
        }
      }
    }
`

const Link = styled(lnk)`
  text-decoration: none;
  max-width: fit-content;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.font.primary} !important;

  transition: all .3s ease-in-out;
  gap: 4px;

  &:hover{
    opacity: 0.9;
  }
`

const UserView = ({ user, title }: { user: User, title?: string }) => {

  return <Link to={`/User/${user.id}`}>
    <UserAvatar key={user.id} user={user} />
    <span>{title ? title : (user.fullName ?? user.username)}</span>
  </Link>
}

const UserSelectorContainer = styled.div`
  padding: 24px;
  max-width: 570px;
  width: 570px;
  
  >.selector-info{
    display: block;
    max-width: 350px;
    line-height: 1;
    font-weight: 300;
    padding: 8px 0px 16px 0px;
  }

  >.button-holder{

    padding-top: 16px;

    >button{
      max-width: 50%;

    }
  }
`

const Title = styled.div`
    >span{
        font-size: 16px;
        font-weight: 600;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    >.ant-btn{
        font-size: 24px;
        line-height: 1;
        background-color: unset !important;
        height: 24px;
        >.ant-btn-icon{
            stroke-width: 1.5;
        }

        &:hover{
            color: ${p => p.theme.colors.font.accent} !important;
        }
    }
`
function UsersUnionPaymentAccount({ union }: UsersUnionTransferProps) {

  const { t } = useTranslation()
  const [userSelectorOpen, setUserSelectorOpen] = useState(false)
  const [newOwner, setNewOwner] = useState(union.currentTransfer?.newOwner)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { user } = useReactiveVar(authState)
  const [transfer] = useUsersUnionTransferMutation()
  const [cancelTransfer] = useUsersUnionTransferCancelMutation()
  const { data: { paymentAccount } = { payment: null } } = useUserPaymentAccountQuery({
    variables: { id: union.paymentAccount.id }
  });


  const transferInProgress = union.currentTransfer ? true : false
  const isOwner = paymentAccount?.managers.some(u => u.id === user.id)

  const showTransferInfo = isOwner && transferInProgress


  const title = t('usersUnion.transfer.transferTooltip')

  const cancelCurentTransfer = () => {

    if (!union.currentTransfer)
      return

    cancelTransfer({
      variables: {
        id: union.currentTransfer.id
      },
      refetchQueries: [RefetchesList.UsersUnion],
      onCompleted() {
        setConfirmOpen(false)
      },
      onError(err) {
        message.error(err.message)
      }
    })
  }

  const changeOwner = () => {

    if (!newOwner) {
      message.error(t('usersUnion.transfer.noUser'))
      return
    }

    transfer({
      variables: {
        usersUnionId: union.id,
        newOwnerId: newOwner.id
      },
      refetchQueries: [RefetchesList.UsersUnion],
      onCompleted() {
        setUserSelectorOpen(false)
      },
      onError(err) {
        message.error(err.message)
      }
    })
  }

  useEffect(()=>{
      setNewOwner(union.currentTransfer?.newOwner)
  }, [union.currentTransfer])
  if (!paymentAccount)
    return <></>

  return (
    <UsersUnionTransferContainer>
      <div className='uu-owners-container'>
        <UserView user={paymentAccount.managers[0] as User} title={paymentAccount.title} />
        {
          showTransferInfo && newOwner && <>
            <Icon size='16' icon={<IconArrowRightWithTail />} />
            <UserView user={union.currentTransfer?.newOwner as User} />
          </>
        }
      </div>
      {showTransferInfo && <div className='uu-transfer-in-progress-message-container'>
        <Icon size='24' icon={<IconClock />} />
        <span>{t("usersUnion.transfer.transferInProgress")}</span>
      </div>}
      <div className='uu-transfer-buttons-container'>
        {showTransferInfo &&
          <>
            <TooltipButton icon={<Icon icon={<IconClose />} size={"24"} />} block
              onClick={() => {
                setConfirmOpen(true)
              }}
              size={"middle"}
              tooltipProps={{
                title: t('usersUnion.transfer.transferCanceling')
              }}
              className='transfer-cancel-btn'
            >{t("usersUnion.transfer.cancelTransfer")
              }
            </TooltipButton>
          </>
        }
        {isOwner && <TooltipButton icon={<Icon icon={<IconUserProject />} size={"24"} />} ghost block
          onClick={() => {
            setUserSelectorOpen(true)
          }}
          size={"middle"}
          tooltipProps={{
            title: title
          }}
        >{t("usersUnion.transfer.changeOwner")}</TooltipButton>}
      </div>
      {
        userSelectorOpen && <Dropdowned
          title={t('usersUnion.transfer.transferTooltip')}
          centerJustify={CenterJustify.center}
          opened={userSelectorOpen}
          onClose={() => setUserSelectorOpen(false)}
          backdrop>
          <UserSelectorContainer>
            {!isMobileOnly && <DropdownedPoptitle text={title} onClose={() => setUserSelectorOpen(false)} />}
            <span className='selector-info'>{t('usersUnion.transfer.userSelectorText')}</span>
            <UserSearch
              addEmails
              addUsersUnions={false}
              maxMembers={1}
              selectedText={t('usersUnion.transfer.userSelectorFooterText')}
              onlyOne={true}
              members={[]}
              invites={[]}
              initialState={newOwner && [newOwner] as UserOrUnionInvite[]}
              membersAdded={(res) => {
                setNewOwner(undefined)

                if (res.users.length > 0) {
                  setNewOwner(res.users[0])
                }

                return true
              }}
            />
            <div className='button-holder'>
              <Button type='primary' block onClick={changeOwner}>{t('usersUnion.transfer.userSelectorFooterText')}</Button>
            </div>
          </UserSelectorContainer>
        </Dropdowned>
      }
      {
        confirmOpen && <MyPopconfirm
          dangerConfirmButton={false}
          title={t('usersUnion.transfer.transferCanceling')}
          body={<>
            {t('usersUnion.transfer.transferCancelingBody', { union: union.title })}
            <span style={{ fontWeight: 500 }}>{newOwner?.fullName ?? newOwner?.username}?</span>
          </>}
          opened={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={cancelCurentTransfer}
          onCancel={() => {setConfirmOpen(false)}}
        />
      }
    </UsersUnionTransferContainer>
  )
}

export default UsersUnionPaymentAccount