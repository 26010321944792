import React, { useMemo } from 'react';
import { message, notification, Popconfirm, Table } from 'antd';
import {
    AccessLevel,
    InviteResolution,
    User,
    UsersUnion, UsersUnionMemberInvite,
    useUsersUnionAddMembersMutation,
    useUsersUnionLeaveMutation,
    useUsersUnionMemberChangeLevelMutation, useUsersUnionMemberInviteRemoveMutation, useUsersUnionMemberRemoveMutation,
} from '../../generated-types';
import { useTranslation } from 'react-i18next';
import AccessLevelSelector from '../AccessLevelSelector';
import { showErrors, showLoading } from '../helpers';
import { authState } from '../../routes/Auth/authContext';
import { useReactiveVar } from '@apollo/client';
import styled, { css } from 'styled-components';
import { IconSorting } from '../Icon/IconSorting';
import UserAvatar from '../User/UserAvatar';
import { IconDelete } from '../Icon/IconDelete';
import { IconStop } from '../Icon/IconStop';
import { allUsersUnions, updateUsersUnionInCache } from '../../subscriptions/allUsersUnions';
import TooltipButton from '../TooltipButton';
import { IconClock } from '../Icon/IconClock';
import { ColumnType } from 'antd/lib/table';
import { IconAttantion } from '../Icon/IconAttantion';
import { UserSearchResults } from '../User/UserSearch';
import UsersUnionMembersEditor from './UsersUnionMembersEditor';
import { useNavigate } from 'react-router-dom';

interface UsersUnionMembersEditorProps {
    isAdmin: boolean,
    usersUnion: UsersUnion
}

const Holder = styled.div<{}>`
    ${p => !p.theme.isMobile && css`
        padding: 40px 24px;
    `}
    max-width: 1141px;
    
    .ant-table-wrapper {
        overflow-y: auto;

        ${p => !p.theme.isMobile && css`
                max-height: calc(100% - 600px);
        `}
    }
`

const TableStyled = styled(Table)<{}>`
    max-width: 1141px;

    .ant-table-column-sorters {
        justify-content: left;

        .ant-table-column-title {
            flex: 0;
        }
    }

    .ant-table-cell.ant-table-column-sort {
        background: none;
    }

    .ant-table-thead {

        .ant-table-cell.ant-table-column-sort {
            background: none;
        }

        .ant-table-cell {
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 300;
            gap: 4px;
            background: none;
        }
    }

    .invited {
        color: ${p => p.theme.colors.ui.bgDark05};
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    .invited.red {
        color: ${p => p.theme.colors.font.red};
    }

    .role-column {
        display: flex;

        .role-holder {
            flex: 1;
        }

        .actions {
            .ant-btn {
                color: ${p => p.theme.colors.ui.bgDark05}
            }
        }
    }
`

interface DataType {
    key: string;
    user?: User;
    accessLevel: AccessLevel;
    invite?: UsersUnionMemberInvite;
}

const UsersUnionMembersList: React.FC<UsersUnionMembersEditorProps> = ({usersUnion, isAdmin}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const [changeLevel, opt2] = useUsersUnionMemberChangeLevelMutation({
        update: (cache, r) => {
            updateUsersUnionInCache(cache, r.data?.usersUnion as UsersUnion)
        }
    });
    const [removeMember, opt3] = useUsersUnionMemberRemoveMutation({
        update: (cache, r) => {
            updateUsersUnionInCache(cache, r.data?.usersUnion as UsersUnion)
        }
    });
    const [leaveUsersUnion] = useUsersUnionLeaveMutation({
        onCompleted: (r) => {
            if (r.usersUnionLeave) {
                navigate('/')
            }
        }
    });
    const [removeInvite] = useUsersUnionMemberInviteRemoveMutation({
        update: (cache, r) => {
            updateUsersUnionInCache(cache, r.data?.usersUnionMemberInviteRemove as UsersUnion)
        }
    });

    const sortIcon = useMemo(() => {
        return (props: { sortOrder: any }) => (props.sortOrder == 'ascend' ? <IconSorting/> : <IconSorting/>)
    }, [])

    const columns: ColumnType<unknown>[] = useMemo(() => {
        return [
            {
                title: 'Имя',
                key: 'name',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <>
                        {d.user && <UserAvatar user={d.user} addTitle={true}/>}
                    </>
                },
            },
            {
                title: 'Эл.почта',
                key: 'email',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.email ?? '') > (b.user?.email ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <span style={{padding: 3}}>{d.user?.email ?? d.invite?.email}</span>
                },
            },
            {
                title: 'Роль',
                key: 'role',
                className: 'role-column',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    const isCurUser = d.user?.id === authInfo.user.id;

                    const selector = <AccessLevelSelector selected={d.accessLevel}
                                                          disabled={!isAdmin}
                                                          onChange={(level: AccessLevel) => {
                                                              if (isCurUser && isAdmin) {
                                                                  messageApi.warning(t('youCantEditYourselfInUsersUnionMembers'));
                                                                  return false;
                                                              } else if (d.user)
                                                                  changeLevel({
                                                                      variables: {
                                                                          input: {
                                                                              userId: d.user.id,
                                                                              usersUnionId: usersUnion.id,
                                                                              level
                                                                          }
                                                                      }
                                                                  })
                                                              return true;
                                                          }
                                                          }/>;

                    return <>
                        <div className={'role-holder'}>
                            {!d.invite && selector}
                            {d.invite && d.user == null && <span className={'invited'}>
                                <IconClock/>{t('invitedByEmail')}
                            </span>}
                            {d.invite && d.user != null && d.invite.resolution === InviteResolution.Received && <span className={'invited'}>
                                <IconClock/>{t('invitedUser')}
                            </span>}
                            {d.invite && d.user != null && d.invite.resolution === InviteResolution.Declined && <span className={'invited red'}>
                                <IconAttantion/>{t('inviteDeclinedByUser')}
                            </span>}
                        </div>
                        {(isAdmin || isCurUser) && <div className={'actions'}>
                            <Popconfirm
                              icon={null}
                              title={t('really')}
                              description={d.invite ?
                                t('inviteSureRemove') : (isCurUser ? t('usersUnion.leaveConfirmDescription') : t('usersUnion.SureRemoveMember'))}
                              onConfirm={() => {
                                  if (d.invite)
                                      removeInvite({variables: {id: d.invite.id}})
                                  else if (d.user) {
                                      if (isCurUser && !isAdmin) {
                                          leaveUsersUnion({
                                              variables: {
                                                  usersUnionId: usersUnion.id
                                              }
                                          })
                                          return false;
                                      } else if (isCurUser && isAdmin) {
                                          messageApi.warning(t('youCantEditYourselfInUsersUnionMembers'));
                                          return false;
                                      } else if (isAdmin)
                                          removeMember({
                                              variables: {
                                                  usersUnionId: usersUnion.id,
                                                  userId: d.user.id
                                              }
                                          })
                                  }
                              }}
                              onCancel={() => {
                              }}
                              okText={t('yes')}
                              cancelText={t('no')}
                            >
                                <TooltipButton type="text" tooltipProps={{
                                    title: isCurUser ? t('usersUnion.leaveTooltip') : t('remove')
                                }}>{
                                    isCurUser ? <IconStop/> : <IconDelete/>
                                }</TooltipButton>
                            </Popconfirm>
                        </div>}
                    </>
                },
            }
        ] as ColumnType<unknown>[];
    }, []);

    const data: DataType[] = useMemo(() => {
        const members = usersUnion.members.map(m => {
            return {
                user: m.user,
                accessLevel: m.accessLevel
            } as DataType
        })

        const invites = (usersUnion.invites ?? []).map(i => {
            return {
                user: i.invitedUser,
                accessLevel: i.accessLevel,
                invite: i,
            } as DataType
        })

        console.log(members, invites)

        return [...members, ...invites];
    }, [usersUnion.members, usersUnion.invites])

    return (
      <Holder>
          {contextHolder}
          {showErrors([opt2, opt3])}
          {showLoading([opt2, opt3])}
          <UsersUnionMembersEditor usersUnion={usersUnion} totalMembers={data.filter(v => !v.invite).length} isAdmin={isAdmin}/>

          <TableStyled
            columns={columns}
            dataSource={data}
            bordered={false}
            pagination={false}
          />
      </Holder>
    );
};

export default UsersUnionMembersList;