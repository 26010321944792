import styled from "styled-components";
import { IconClose } from "./Icon/IconClose";
import { Button } from "antd";

//Общий заголовок для попапов, тоже много где встречается

const Title = styled.div`
    >span{
        font-size: 16px;
        font-weight: 600;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    >.ant-btn{
        font-size: 24px;
        line-height: 1;
        background-color: unset !important;
        height: 24px;
        >.ant-btn-icon{
            stroke-width: 1.5;
        }

        &:hover{
            color: ${p => p.theme.colors.font.accent} !important;
        }
    }
`

function DropdownedPoptitle({ text, onClose }: { text: string | JSX.Element, onClose: () => void }) {
    return (
        <Title>
            <span>{text}</span>
            <Button type="text" size="small" icon={<IconClose />} onClick={onClose} />
        </Title>
    )
}

export default DropdownedPoptitle