import React from 'react';
import SignIn from "./routes/Auth/SignIn";
import SignUp from "./routes/Auth/SignUp";
import ErrorPage from "./ErrorPage";
import ProjectsList from "./Components/Project/ProjectsList";
import { createBrowserRouter, useNavigate } from 'react-router-dom';
import App from "./App";
import {useReactiveVar} from "@apollo/client";
import {authState, clearToken} from "./routes/Auth/authContext";
import ProjectCard from "./Components/Project/ProjectCard";
import Profile from "./Components/User/Profile";
import UsersUnionNew from "./Components/UsersUnion/UsersUnionNew";
import UsersUnionCard from "./Components/UsersUnion/UsersUnionCard";
import MyTasks from "./Components/MyTasks";
import NotificationsList from "./Components/NotificationsList";
import RestorePassword from "./routes/Auth/RestorePassword";
import OtherUserProfile from './Components/User/OtherUserProfile';
import {TariffsTable} from "./Components/TariffsTable/tariffsTable";
import {TermsOfUse} from "./routes/TermsOfUse";
import {PersonalDataAcceptance} from "./routes/PersonalDataAcceptance";
import {Contacts} from "./routes/Contacts";
import GeneralBoardTesting from './Components/GeneralBoard/GeneralBoardTesting';
import {menuItems} from "./Components/AntCastomComponents/LeftMenu/LeftMenu";
import Spinner from './Components/Spinner';
import UsersUnionReadOnlyCard from './Components/UsersUnion/UsersUnionReadOnlyCard';


interface ffff {
    ch: any
}

export const OnlyAuth: React.FC<ffff> = (props: ffff) => {
    const authInfo = useReactiveVar(authState);

    if (!authInfo.user) {
        return <SignIn />;
    }
    return props.ch;
};

export const NotAuth: React.FC<ffff> = (props: ffff) => {
    return props.ch;
};

export const OnlyNotAuth: React.FC<ffff> = (props: ffff) => {
    const authInfo = useReactiveVar(authState);
    const navigate = useNavigate();

    if (!authInfo.user) {
        return props.ch;
    }

    navigate('/');

    return <Spinner></Spinner>;
};

export const routes = {
    projectTask: "/project/:projectId/task/:taskId",
    usersUnionCard: "/usersUnion/:id",
    userCard: "/user/:id",

}

const AppRouter = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <OnlyAuth ch = {<ProjectsList />} />
            },
            {
                path: menuItems.personalProject,
                element: <OnlyAuth ch={<ProjectsList onlyMyProjects={true}/>}/>
            },
            {
                path: menuItems.uuProjects,
                element: <OnlyAuth ch={<ProjectsList onlyUsersUnions={true}/>}/>
            },
            {
                path: menuItems.otherProjects,
                element: <OnlyAuth ch={<ProjectsList onlyPeoples={true}/>}/>
            },
            {
                path: "signin",
                element: <OnlyNotAuth ch = {<SignIn />} />
            },
            {
                path: "signup",
                element: <OnlyNotAuth ch = {<SignUp />} />
            },
            {
                path: "rp/:link?",
                element: <NotAuth ch = {<RestorePassword />} />
            },
            {
                path: "invite/:linkId",
                element: <NotAuth ch = {<UsersUnionReadOnlyCard />} />
            },
            {
                path: "uutransfer/:linkId",
                element: <NotAuth ch = {<UsersUnionReadOnlyCard />} />
            },

            {
                path: "profile",
                element: <OnlyAuth ch = {<Profile />} />
            },

            {
                path: "mytasks",
                element: <OnlyAuth ch = {<MyTasks />} />
            },

            {
                path: "notifications",
                element: <OnlyAuth ch = {<NotificationsList />} />
            },
            {
                path: "project/:id",
                element: <OnlyAuth ch = {<ProjectCard />} />,
                children: [
                    {
                        path: ":tab",
                        element: <OnlyAuth ch = {<ProjectCard />} />,
                    },
                    {
                        path: "task?/:taskId",
                        element: <OnlyAuth ch={<ProjectCard/>}/>,
                    }
                ]
            },
            {
                path: "projectro/:id",
                element: <ProjectCard readOnly={true} />,
            },
            {
                path: "user/:id",
                element: <OnlyAuth ch = {<OtherUserProfile />} />
            },
            {
                path: "usersUnion/new",
                element: <OnlyAuth ch = {<UsersUnionNew />} />
            },
            {
                path: "usersUnion/:id",
                element: <OnlyAuth ch = {<UsersUnionCard />} />,
                children: [
                    {
                        path: ":tab",
                        element: <OnlyAuth ch = {<UsersUnionCard />} />,
                    }
                ]
            },
            {
                path: "tariffs",
                element: <NotAuth ch = {<TariffsTable />} />
            },
            {
                path: "termsofuse",
                element: <NotAuth ch = {<TermsOfUse />} />
            },
            {
                path: "contacts",
                element: <NotAuth ch = {<Contacts />} />
            },
            {
                path: "PersonalDataAcceptance",
                element: <NotAuth ch = {<PersonalDataAcceptance />} />
            },
            {
                path: 'generalBoardTesting',
                element: <GeneralBoardTesting/>
            }
        ],
    },
]);


export default AppRouter