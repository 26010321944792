import React, { useState } from 'react'
import Dropdowned, { CenterJustify } from './Dropdowned'
import styled from 'styled-components'
import DropdownedPoptitle from './DropdownedPoptitle'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

//Общая модалка для подтверждения каких-либо действий

interface PopconfirmProps {
    title: string | JSX.Element,
    body: string | JSX.Element,
    onConfirm: () => void,
    onCancel: () => void,
    confirmText?: string,
    cancelText?: string,
    opened: boolean,
    onClose: () => void,
    dangerConfirmButton?: boolean //в дизайнах где-то красный, где-то оранжевый, пришлось добавить такую штуку
}

const ConfirmContainer = styled.div`
    width: 290px;
    padding: 16px;
    

    >.confirm-body{
        display: block;
        font-size: 12px;
        font-weight: 300;
        padding: 8px 0px 12px;
    }
    >.confirm-buttons-holder{
        display: flex;
        gap: 8px;

        >.cancel-btn{
            color: ${p=>p.theme.colors.font.primary};
        }

        >button{
            flex-grow: 1;
            font-size: 12px;
            max-width: calc(50% - 4px);
            line-height: 1;
            padding: 8px;
        }
    }
`

function MyPopconfirm({ title, body, onConfirm, onCancel, confirmText, cancelText, opened, onClose, dangerConfirmButton=true }: PopconfirmProps) {

    const {t}=useTranslation()

    return (
        <Dropdowned centerJustify={CenterJustify.center} opened={opened} onClose={onClose} popupOnly backdrop>
            <ConfirmContainer>
                <DropdownedPoptitle text={title} onClose={onClose} />
                <span className='confirm-body'>{body}</span>
                <div className='confirm-buttons-holder'>
                    <Button onClick={onConfirm} danger={dangerConfirmButton} type='primary'>{confirmText??t('ok')}</Button>
                    <Button onClick={onCancel} ghost className='cancel-btn'>{cancelText??t('cancel')}</Button>
                </div>
            </ConfirmContainer>
        </Dropdowned>
    )
}

export default MyPopconfirm