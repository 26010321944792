import React, {useEffect, useMemo, useState} from 'react';
import {SearchOutlined} from '@ant-design/icons';
import {Col, Divider, Input, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {Project, ProjectMemberInvite, UsersUnion} from "../../generated-types";
import ProjectNew from "../Project/ProjectNew";
import ProjectsFilter, {AvailableFilters, AvailableFlags, FilterKeyItem} from "../Project/ProjectsFilter";
import {isMobile} from "react-device-detect";
import {IconBoards} from "../Icon/IconBoards";
import {IconTasks} from "../Icon/IconTasks";
import IconStateSwitcher, {IconState} from "../Tasks/IconStateSwitcher";
import UsersUnionProjectsBoard from "./UsersUnionProjectsBoard";
import ProjectsGroup from "../Project/ProjectsGroup";
import ProcessProjectInvite from "./ProcessProjectInvite";
import ProjectsListCard from "../Project/ProjectsListCard";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import styled from 'styled-components';
import SearchInput from '../SearchInput';
import { NO_TAGS } from '../Tasks/TaskView/TaskViewFilter';


enum DisplayView {
    list,
    board
}


// TODO: настройки конкретного отображения задач надо сохранять на сервере для конкретного пользователя и оттуда же считывать
const getDefaultProjectsDisplayParams = (id: String): UUDisplayParams => {
    const txt = localStorage.getItem(`uu${id}`);

    let res = {} as UUDisplayParams;

    if (txt) {
        const saved = JSON.parse(txt);
        if (saved) {
            res = saved as UUDisplayParams
        }
    }
    if (!res.displayView)
        res.displayView = DisplayView.board;

    return res
}

const saveProjectsDisplayParams = (id: String, p: UUDisplayParams) => {
    const s = JSON.stringify(p);
    localStorage.setItem(`uu${id}`, s);
}

interface UUDisplayParams {
    displayView: DisplayView,
    archived: boolean,
    filter: FilterKeyItem[],
}

export interface UsersUnionProjectsListProps {
    union: UsersUnion;
    hideFilter: boolean;
    onChange?: (checked: boolean) => void;
}

const ProjectsListActions=styled.div`
    width: 100%;
    display: flex; 
    gap: 16px;
    padding: 16px 40px 24px;
`

const UsersUnionProjectsList: React.FC<UsersUnionProjectsListProps> = ({ union, onChange, hideFilter }) => {
    const { t } = useTranslation();
    const [displayParams, setDisplayParams] = useState<UUDisplayParams>({} as UUDisplayParams);
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);
    const [search, setSearch] = useState<string | undefined>();
    const authInfo = useReactiveVar(authState);

    useEffect(() => {
        // TODO: надо разобраться, как не вызывать сохранение сразу после загрузки из локалстореджа
        if (displayParams.displayView === undefined) {
            setDisplayParams(getDefaultProjectsDisplayParams(union.id))
        } else
            saveProjectsDisplayParams(union.id, displayParams)
    }, [displayParams]);

    const onSearch = (value: string) => {
        setSearch(value)
    }

    const allProjects: Project[] = useMemo(() => {
        let res = union.ownedProjects
        if (union.projectInvites)
            res = res.concat(union.projectInvites.map(i => i.project));
        return res;
    }, [union.ownedProjects, union.projectInvites])

    const projects: Project[] = useMemo(() => {
        if (hideFilter) return union.ownedProjects;

        let res: Project[] = [];

        const f = displayParams?.filter;

        if (f && f.find(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.invites)) {
            res = union.projectInvites.map(i => i.project);
        } else {
            res = union.ownedProjects
            if (union.projectInvites)
                res = res.concat(union.projectInvites.map(i => i.project));
        }

        if (search?.length && search) {
            res = res.filter((p) => (
                p.title.toUpperCase().includes(search) ||
                p.usersUnionAsOwner?.title.toUpperCase().includes(search) ||
                p.members.some((m) => m.user.username.toUpperCase().includes(search) || m.user.fullName?.toUpperCase().includes(search))
            ))
        }

        if (f) {
            const iamMember = f.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.iamMember);
            if (iamMember) {
                res = res.filter(t => t.members.some(m => m.user.id === authInfo.user.id))
            }

            if (f.filter(f => f.type == AvailableFilters.tags).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.tags).map(t => t.key);
                res = res.filter(t => {
                    // Проект должн содержать все метки, которые указаны в фильтре
                    const l1 = ff.filter(f => t.attachedTags.some(tg => f === tg)).length;
                    const l2 = ff.length;
                    return !t.tags.length && ff.some(v => v == NO_TAGS) || l1 === l2;
                })
            }

            if (f.filter(f => f.type == AvailableFilters.members).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.members).map(t => t.key);
                res = res.filter(t => t.members.some(m => ff.some(f => m.user.id == f)))
            }

            if (f.filter(f => f.type == AvailableFilters.owners).length > 0) {
                const ff = f.filter(f => f.type == AvailableFilters.owners).map(t => t.key);

                res = res.filter(t => ff.some(f => {
                    if (f.startsWith("UsersUnion|") && union.ownedProjects.some(op => op.id == t.id)) {
                        return f.replace("UsersUnion|", "") === union.id;
                    }
                    else if (f.startsWith("UsersUnion|")) {
                        return t.usersUnionAsOwner?.id == f.replace("UsersUnion|", "");
                    }
                    else if (f.startsWith("User|")) {
                        return t.userAsOwner?.id == f.replace("User|", "")
                    }
                    return false;
                }))
            }
        }

        return res;
    }, [hideFilter, allProjects, displayParams, search,union.ownedProjects, union.projectInvites])

    return <>
        {hideFilter && <div style={{height: 20}}></div>}
        {!hideFilter && <ProjectsListActions>
            <ProjectsFilter projects={allProjects}
                             disable={!allProjects}
                             showFilters={{
                                 owners: true,
                                 tags: true,
                                 flags: true,
                                 members: true
                             }}
                             onFilterChanged={(filter: FilterKeyItem[]) => {
                                 const archived = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.archived);
                                 const active = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.active);

                                 // Правильно выключение-включение лишних галочек, т.к. могут показываться либо проекты в архиве либо активные
                                 if (archived && active && !displayParams.archived) {
                                     filter = filter.filter(v => v.key !== AvailableFlags.active)
                                 } else if (active && archived && displayParams.archived) {
                                     filter = filter.filter(v => v.key !== AvailableFlags.archived)
                                 }

                                 setDisplayParams({...displayParams, archived, filter})

                                 if (displayParams.archived != archived && onChange)
                                     onChange(archived);

                                 return filter;
                             }}/>
            <SearchInput placeHolder={t('project.search') as string} onSearch={onSearch}/>
            <ProjectNew isBlocked={union.paymentAccount?.isBlocked} context={union}/>
        </ProjectsListActions>}
            {displayParams.displayView == DisplayView.board &&
					    <UsersUnionProjectsBoard union={union} projects={projects} invites={union.projectInvites}/>}
            {displayParams.displayView == DisplayView.list &&
			        <span>
                <ProjectsGroup projects={projects} context={union.currentUserIsMember ? union : undefined}/>
                  {union.projectInvites.length > 0 && <>
                      {inviteInfo && <ProcessProjectInvite key={inviteInfo?.id} invite={inviteInfo} union={union}
							                                             onClose={() => setInviteInfo(null)}/>}
						        <Divider>{t('usersUnion.projectInvites')}</Divider>
						        <Row gutter={[16, 16]}>{
                        union.projectInvites.map((inv: ProjectMemberInvite) => {
                                const {project} = inv;
                                return <Col key={inv.project.id} flex="300px">
                                    <ProjectsListCard key={project.id} project={project }
                                                      showButtons={false} showFavorite={true}
                                                      onProjectClick={() => {
                                                          setInviteInfo(inv)
                                                      }}
                                    /></Col>;
                            }
                        )}</Row></>
                  }
            </span>
            }
    </>
};

export default UsersUnionProjectsList;
