import React from 'react';
import {Alert, Col, Row, Watermark} from 'antd';
import {useTranslation} from "react-i18next";
import {
    useUsersUnionReadOnlyQuery,

} from '../../generated-types';
import {useParams} from "react-router-dom";
import Spinner from "../Spinner";
import {isMobile} from "react-device-detect";
import { UserUnionHeaderContainer } from './UsersUnionHeader';
import UsersUnionsAvatar from './UsersUnionsAvatar';
import UsersUnionProjectsBoard from './UsersUnionProjectsBoard';

const UsersUnionReadOnlyCard: React.FC = () => {
    const {t} = useTranslation();
    const { linkId } = useParams();
    const { loading, error, data } = useUsersUnionReadOnlyQuery({
        variables: {
            linkId: linkId!,
        }
    });

    if (loading) return <Spinner loading />;
    if (error) return<Alert type="error" message={error.message} banner />;

    let union: any = data == null ? null : data.usersUnionReadOnly;

    if (!union)
        return <div>define right usersUnion id please</div>

    return (
        <Watermark
            content={undefined}
            style={{display: "flex", flexDirection: "column", flexGrow: 1, maxHeight: '100%'}}
            >
            <UserUnionHeaderContainer>
                <div className='team-title-container'>
                    <UsersUnionsAvatar avatarUrl={union.avatarUrl} size={isMobile? '24' : '32'} />
                    <span className='team-title'>{t("usersUnion.teamTitle", { team: union.title })}</span>
                </div>
                <div className='tabs-container'>
                    {/*{*/}
                    {/*    isMobile? <div className='tabs-scroll'>{tabs}</div> : tabs*/}
                    {/*}*/}
                </div>

            </UserUnionHeaderContainer>
            <Row style={{margin: 0, flexGrow: 1, overflow: 'hidden'}}>
                <Col span={24} style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column'}}>
                    <UsersUnionProjectsBoard union={union} projects={[]} invites={union.projectInvites}/>
                </Col>
            </Row>
        </Watermark>

    )
};

export default UsersUnionReadOnlyCard;
