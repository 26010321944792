import React, {type SVGProps} from "react"

export function IconArrowRightWithTail(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
<g id="name=arrow right, size=16">
<path id="Line 42" d="M2 7.5C1.72386 7.5 1.5 7.72386 1.5 8C1.5 8.27614 1.72386 8.5 2 8.5L2 7.5ZM2 8.5L14 8.5L14 7.5L2 7.5L2 8.5Z" fill="currentColor" strokeOpacity="0"/>
<path id="Vector" d="M10 12L14 8L10 4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>

    )
}
