 import {IconAdditionalMenu} from "./IconAdditionalMenu"
 import {IconArchive} from "./IconArchive"
 import {IconArrowDown} from "./IconArrowDown"
 import {IconArrowLeft} from "./IconArrowLeft"
 import {IconArrowRight} from "./IconArrowRight"
 import {IconArrowRightWithTail} from "./IconArrowRightWithTail"
 import {IconArrowUp} from "./IconArrowUp"
 import {IconAttantion} from "./IconAttantion"
 import {IconBack} from "./IconBack"
 import {IconBoards} from "./IconBoards"
 import {IconCalend} from "./IconCalend"
 import {IconCheck} from "./IconCheck"
 import {IconClock} from "./IconClock"
 import {IconClose} from "./IconClose"
 import {IconColorBlue} from "./IconColorBlue"
 import {IconColorGreen} from "./IconColorGreen"
 import {IconColorOrange} from "./IconColorOrange"
 import {IconColorPurple} from "./IconColorPurple"
 import {IconComment} from "./IconComment"
 import {IconCommentHide} from "./IconCommentHide"
 import {IconCommentHistory} from "./IconCommentHistory"
 import {IconCommentShow} from "./IconCommentShow"
 import {IconCopyLink} from "./IconCopyLink"
 import {IconDelete} from "./IconDelete"
 import {IconDetails} from "./IconDetails"
 import {IconDocs} from "./IconDocs"
 import {IconDocsAdd} from "./IconDocsAdd"
 import {IconDownload} from "./IconDownload"
 import {IconDragTask} from "./IconDragTask"
 import {IconEdit} from "./IconEdit"
 import {IconEnter} from "./IconEnter"
 import {IconFavourites} from "./IconFavourites"
 import {IconFile} from "./IconFile"
 import {IconFiles} from "./IconFiles"
 import {IconFilter} from "./IconFilter"
 import {IconFolder} from "./IconFolder"
 import {IconHelp} from "./IconHelp"
 import {IconHistory} from "./IconHistory"
 import {IconHome} from "./IconHome"
 import {IconLabel} from "./IconLabel"
 import {IconMainWorker} from "./IconMainWorker"
 import {IconMan} from "./IconMan"
 import {IconMenu} from "./IconMenu"
 import {IconNotification} from "./IconNotification"
 import {IconPlus} from "./IconPlus"
 import {IconPriority} from "./IconPriority"
 import {IconPriorityHigh} from "./IconPriorityHigh"
 import {IconProjects} from "./IconProjects"
 import {IconRock} from "./IconRock"
 import {IconSearch} from "./IconSearch"
 import {IconSend} from "./IconSend"
 import {IconSetting} from "./IconSetting"
 import {IconSort} from "./IconSort"
 import {IconSorting} from "./IconSorting"
 import {IconStart} from "./IconStart"
 import {IconStop} from "./IconStop"
 import {IconSubTasks} from "./IconSubTasks"
 import {IconTag} from "./IconTag"
 import {IconTasks} from "./IconTasks"
 import {IconTeam} from "./IconTeam"
 import {IconUser} from "./IconUser"
 import {IconUserEdit} from "./IconUserEdit"
 import {IconUserProject} from "./IconUserProject";

export const AllIconsDict: {[key: string]: any} = {
"AdditionalMenu": <IconAdditionalMenu />,
"Archive": <IconArchive />,
"ArrowDown": <IconArrowDown />,
"ArrowLeft": <IconArrowLeft />,
"ArrowRight": <IconArrowRight />,
"ArrowRightWithTail": <IconArrowRightWithTail />,
"ArrowUp": <IconArrowUp />,
"Attantion": <IconAttantion />,
"Back": <IconBack />,
"Boards": <IconBoards />,
"Calend": <IconCalend />,
"Check": <IconCheck />,
"Clock": <IconClock />,
"Close": <IconClose />,
"ColorBlue": <IconColorBlue />,
"ColorGreen": <IconColorGreen />,
"ColorOrange": <IconColorOrange />,
"ColorPurple": <IconColorPurple />,
"Comment": <IconComment />,
"CommentHide": <IconCommentHide />,
"CommentHistory": <IconCommentHistory />,
"CommentShow": <IconCommentShow />,
"CopyLink": <IconCopyLink />,
"Delete": <IconDelete />,
"Details": <IconDetails />,
"Docs": <IconDocs />,
"DocsAdd": <IconDocsAdd />,
"Download": <IconDownload />,
"DragTask": <IconDragTask />,
"Edit": <IconEdit />,
"Enter": <IconEnter />,
"Favourites": <IconFavourites />,
"File": <IconFile />,
"Files": <IconFiles />,
"Filter": <IconFilter />,
"Folder": <IconFolder />,
"Help": <IconHelp />,
"History": <IconHistory />,
"Home": <IconHome />,
"Label": <IconLabel />,
"MainWorker": <IconMainWorker />,
"Man": <IconMan />,
"Menu": <IconMenu />,
"Notification": <IconNotification />,
"Plus": <IconPlus />,
"Priority": <IconPriority />,
"PriorityHigh": <IconPriorityHigh />,
"Projects": <IconProjects />,
"Rock": <IconRock />,
"Search": <IconSearch />,
"Send": <IconSend />,
"Setting": <IconSetting />,
"Sort": <IconSort />,
"Sorting": <IconSorting />,
"Start": <IconStart />,
"Stop": <IconStop />,
"SubTasks": <IconSubTasks />,
"Tag": <IconTag />,
"Tasks": <IconTasks />,
"Team": <IconTeam />,
"User": <IconUser />,
"UserEdit": <IconUserEdit />,
"UserProject": <IconUserProject />
};

export enum AvailableIcons {
"AdditionalMenu" = "AdditionalMenu",
"Archive" = "Archive",
"ArrowDown" = "ArrowDown",
"ArrowLeft" = "ArrowLeft",
"ArrowRight" = "ArrowRight",
"ArrowRightWithTail" = "ArrowRightWithTail",
"ArrowUp" = "ArrowUp",
"Attantion" = "Attantion",
"Back" = "Back",
"Boards" = "Boards",
"Calend" = "Calend",
"Check" = "Check",
"Clock" = "Clock",
"Close" = "Close",
"ColorBlue" = "ColorBlue",
"ColorGreen" = "ColorGreen",
"ColorOrange" = "ColorOrange",
"ColorPurple" = "ColorPurple",
"Comment" = "Comment",
"CommentHide" = "CommentHide",
"CommentHistory" = "CommentHistory",
"CommentShow" = "CommentShow",
"CopyLink" = "CopyLink",
"Delete" = "Delete",
"Details" = "Details",
"Docs" = "Docs",
"DocsAdd" = "DocsAdd",
"Download" = "Download",
"DragTask" = "DragTask",
"Edit" = "Edit",
"Enter" = "Enter",
"Favourites" = "Favourites",
"File" = "File",
"Files" = "Files",
"Filter" = "Filter",
"Folder" = "Folder",
"Help" = "Help",
"History" = "History",
"Home" = "Home",
"Label" = "Label",
"MainWorker" = "MainWorker",
"Man" = "Man",
"Menu" = "Menu",
"Notification" = "Notification",
"Plus" = "Plus",
"Priority" = "Priority",
"PriorityHigh" = "PriorityHigh",
"Projects" = "Projects",
"Rock" = "Rock",
"Search" = "Search",
"Send" = "Send",
"Setting" = "Setting",
"Sort" = "Sort",
"Sorting" = "Sorting",
"Start" = "Start",
"Stop" = "Stop",
"SubTasks" = "SubTasks",
"Tag" = "Tag",
"Tasks" = "Tasks",
"Team" = "Team",
"User" = "User",
"UserEdit" = "UserEdit",
"UserProject" = "UserProject"
}

