import React from "react";
import UserAvatar from "./UserAvatar";
import UsersUnionsAvatar from "../UsersUnion/UsersUnionsAvatar";
import {UsersUnion} from "../../generated-types";
import {UserOrUnionInvite} from "./UserSearch";
import styled from "styled-components";
import {useTranslation} from "react-i18next";


const ItemHolder = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    padding: 0px 16px;
    &:hover {
        background-color: ${p => p.theme.colors.ui.bgLight3};
    }

    .title-holder {
        display: flex;
        flex-direction: column;
        gap: 2px;
        flex-grow: 1;
    }

    .children-holder {
        flex-grow: 1;
    }

    .subtitle {
        color: ${p => p.theme.colors.font.primarySemitransparent};
    }

    .ant-input-number {
        background-color: transparent;
    }
`

const UserOrUnionListItem: React.FC<{
    item: UserOrUnionInvite,
    onClick?: (u: UserOrUnionInvite) => void,
    children?: React.ReactNode
}> = ({item, onClick, children}) => {
    const {t} = useTranslation()

    if (item.__typename == 'User')
        return <ItemHolder key={item.id} onClick={() => onClick && onClick(item)}>
            <UserAvatar user={item} size={"32"}/>
            <div className={"title-holder"}>
                <div>{item.fullName ?? item.username}</div>
                {item.fullName && <div className={"subtitle"}>{item.username}</div>}
            </div>
            <div className={"children-holder"}>
                {children}
            </div>
        </ItemHolder>
    else
        return <ItemHolder key={item.id} onClick={() => onClick && onClick(item)}>
            <UsersUnionsAvatar avatarUrl={item.avatarUrl} size={"32"}/>
            <div className={"title-holder"}>
                <div>{(item as UsersUnion).title}</div>
                <div className={"subtitle"}>{(item as UsersUnion).name}</div>
            </div>
            <div className={"children-holder"}>
                {children}
            </div>
        </ItemHolder>
}

export default UserOrUnionListItem;