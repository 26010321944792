import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {Button, Input, theme, Tooltip} from "antd";
import {SearchOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {Project, User, UsersUnion} from "../../../generated-types";
import {allUsersUnions} from "../../../subscriptions/allUsersUnions";
import {allProjects} from "../../../subscriptions/allProjects";
import {CustomMenu, LeftSider, TitleContainer, TitleText} from "./LeftMenu.styled";
import {LeftMenuButtons} from "./LeftMenuButtons/LeftMenuButtons";
import {LeftMenuCollapseButton} from "../LeftMenuCollapseButton";
import {isMobile} from "react-device-detect";
import styled, {css} from "styled-components";
import PointDivider from "../../PointDivider";
import UsersUnionsAvatar from "../../UsersUnion/UsersUnionsAvatar";
import UserAvatar from "../../User/UserAvatar";
import {IconFavourites} from "../../Icon/IconFavourites";
import {IconProjects} from "../../Icon/IconProjects";
import {IconTeam} from "../../Icon/IconTeam";
import {IconMan} from "../../Icon/IconMan";
import {IconArrowDown} from "../../Icon/IconArrowDown";
import {IconArrowUp} from "../../Icon/IconArrowUp";
import {Icon} from "../../Icon/Icon";
import ProjectAvatar from "../../Project/ProjectAvatar";
import {AuthState, authState} from "../../../routes/Auth/authContext";


export interface LeftMenuProps {
    contentMargin: number,
    setContentMargin: (number: number) => void
    collapsed: boolean
    setCollapsed: (v: boolean) => void
}

export const menuItems: any = {
    personalProject: '/my',
    uuProjects: '/uu',
    otherProjects: '/otherp',
}


export const LeftMenuSearch = styled(Input)`
    margin: 12px;
    width: 90%;
    border-radius: 8px;
    background-color: ${p => p.theme.colors.ui.bgDark};

    color: ${p => p.theme.colors.font.white};

    ::placeholder {
        color: ${p => p.theme.colors.font.white};
        opacity: 0.5; /* Firefox */
    }

    :focus {
        color: red;
    }
`

export const ItemMenuElementStyled = styled.div<{ $collapsed: string }>`
    & {
        display: flex;
        //align-items: center;
        justify-content: flex-start;
        color: ${p => p.theme.colors.font.white};
        padding-bottom: 3px;
        padding-top: 3px;

        ${({$collapsed}) => $collapsed === "true" && css`
            width: 100%;
            //align-self: center;
            justify-content: center;
        `};

        a {
            font-weight: 100;
            padding-left: 5px;
            color: ${p => p.theme.colors.font.white};
            line-height: 1.2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            align-content: space-around;
        }
    }

    &:hover {
        background-color: ${p => p.theme.colors.ui.accent05};
    }
`

interface ItemMenuElementProps {
    project: Project,
    menuCollapsed: boolean,
    onItemClick: (link: string) => void
}

export const ItemMenuElement: React.FC<ItemMenuElementProps> = ({menuCollapsed, project, onItemClick}) => {
    const to = `/project/${project.id}`;
    const projectIcon = <ProjectAvatar icon={project.icon} iconColor={project.iconColor}/>;

    const className = "project-title" + (currentMenuIsSelected(to) ? " selected" : "");

    const tooltip = <div>
        {project.usersUnionAsOwner && <div className={"rightAlign"}><UsersUnionsAvatar
					uu={project.usersUnionAsOwner}/>{project.usersUnionAsOwner?.title}</div>
        }
        {project.userAsOwner &&
			    <div className={"rightAlign"}><UserAvatar user={project.userAsOwner} addTitle={true}/></div>
        }
        <div>{project.title}</div>
    </div>;

    let element = <ItemMenuElementStyled $collapsed={"" + menuCollapsed} className={className} onClick={() => {
        onItemClick(to)
    }}>
        {projectIcon}<a>{!menuCollapsed && project.title}</a>
    </ItemMenuElementStyled>

    if (!isMobile)
        return <Tooltip title={tooltip} placement={"right"}>{element}</Tooltip>

    return element;
}

interface GroupMenuElementProps {
    menuCollapsed: boolean
    e: MenuEntity
    onItemClick: (link: string) => void
}

export const GroupMenuElement: React.FC<GroupMenuElementProps> = ({menuCollapsed, e, onItemClick}) => {
    const {token} = theme.useToken();
    const {children, entity} = e;
    const [expanded, setExpanded] = useState(false)

    let link: string = `/${entity.__typename}/${entity.id}`;
    let title: string = "";
    let icon: ReactElement | null = null;
    let k = (entity as any).__typename + "_" + (entity as any).id;

    if (e.entity?.__typename == "UsersUnion") {
        title = (entity as UsersUnion)?.title
        icon = <UsersUnionsAvatar uu={entity as UsersUnion}/>;
    } else if (e.entity?.__typename == "User") {
        title = (entity as User)?.username
        icon = <UserAvatar user={entity as User}/>;
    } else if (e.entity?.__typename == "Project") {
        return <ItemMenuElement key={k} onItemClick={onItemClick}
                                menuCollapsed={menuCollapsed}
                                project={entity as Project}/>
    }

    // if (children == undefined || children.length == 0)
    //     return <NoProjectsElement menuCollapsed={menuCollapsed} />;

    const arrow = <div style={{
        backgroundColor: menuCollapsed ? token.colors.ui.bgDark : undefined, width: 20, height: 20, borderRadius: 4,
        color: token.colors.font.white
    }}
                       onClick={(e) => {
                           setExpanded(!expanded)
                           e.preventDefault()
                           e.stopPropagation()
                       }}>
        {
            expanded
                ? <IconArrowUp style={{width: 16, height: 16, margin: 3}} color={token.colors.font.white}/>
                : <IconArrowDown style={{width: 16, height: 16, margin: 3}} color={token.colors.font.white}/>
        }
    </div>;

    const titletxt = <div className={"title" + (currentMenuIsSelected(link) ? " selected" : "")} onClick={(e) => {
        onItemClick(link)
        e.preventDefault()
        e.stopPropagation()
    }}>{icon}<TitleText collapsed={menuCollapsed ? "true" : undefined}>{title}
        {children.length > 0 && <PointDivider value={children.length}/>}
        {!menuCollapsed && children.length > 0 && arrow}
        {/*{children.length > 0 && <Button type={"link"} style={{width: 24, height: 24}}><IconArrowDown /></Button>}*/}
    </TitleText></div>;

    return <TitleContainer className={"title-container"}>
        {/*{menuCollapsed ? <Tooltip title={title}>{titletxt}</Tooltip> : titletxt}*/}
        {titletxt}

        {menuCollapsed && children.length > 0 &&
					<div className={"title"} onClick={(e) => {
          }}>{arrow}</div>
        }
        <div className="items-holder">
            {
                expanded && children
                    .map(entity => {
                        return (<ItemMenuElement key={link} menuCollapsed={menuCollapsed} onItemClick={onItemClick}
                                                 project={entity.entity as Project}/>)
                    })
            }
        </div>
    </TitleContainer>;
}

const MenuItemCollapseButton=styled(Button)<{$isCollapsed: boolean}>`
    color: ${p=>p.theme.colors.font.white};
    font-size: 24px;

    >.ant-btn-icon{
        opacity: 0.5;
        transform: ${p=>p.$isCollapsed? 'rotate(-180deg)' : 'none'};
        transition: all .3s ease-in-out;
    }
`

interface TopMenuElementProps {
    menuCollapsed: boolean
    icon: ReactElement
    title: string
    link: string
    children: MenuEntity[]
    onItemClick: (link: string) => void,
    isCollapsed?: boolean,
    onCollapsedChange?: (value: boolean)=>void
}

export const TopMenuElement: React.FC<TopMenuElementProps> = ({
                                                                  menuCollapsed,
                                                                  icon,
                                                                  link,
                                                                  title,
                                                                  children,
                                                                  onItemClick,
                                                                  isCollapsed=false,
                                                                  onCollapsedChange
                                                              }) => {

    //const [isCollapsed, setCollapsed]=useState(collapsed)
    const holderRef=useRef<HTMLDivElement>(null)

    // TODO: при переключении между маршрутами меню дергает, приколы с display не очень работают, надо подумать
    useEffect(()=>{
        const holder=holderRef.current
        const holderContentHeight=holder?.scrollHeight+'px'
        if(holder){
            
            //holder.style.display='block'
            if(isCollapsed){
                holder.style.height= holderContentHeight
                window.requestAnimationFrame(()=>
                    window.requestAnimationFrame(()=>{
                    holder.style.height='0px';
                }))

                /*const onTransitonEnd = () => {
                    holder.style.display='none'
                }

                holder.addEventListener("transitionend", onTransitonEnd)

                return ()=>{
                    holder.removeEventListener("transitionend", onTransitonEnd)
                }*/
            
            }
            else {
                window.requestAnimationFrame(()=>
                    window.requestAnimationFrame(()=>{
                    holder.style.height= holderContentHeight
                }))
                
                
                const onTransitonEnd = () => {
                        holder.style.height='auto'
                    }

                    holder.addEventListener("transitionend", onTransitonEnd)

                    return ()=>{
                        holder.removeEventListener("transitionend", onTransitonEnd)
                    }
                }
        }
        

    }, [isCollapsed, children])

    const collapseButtonClickHandler=(e: any)=>{
            e.preventDefault()
            e.stopPropagation()

            onCollapsedChange && onCollapsedChange(!isCollapsed)
           // setCollapsed(prev=>(!prev))
    }                                

    const titletext = <div className={"title" + (currentMenuIsSelected(link) ? " selected" : "")} onClick={(e) => {
        onItemClick(link);
        e.preventDefault()
        e.stopPropagation()
    }}>{icon}<TitleText collapsed={menuCollapsed ? "true" : undefined}>{title}
        {children.length > 0 && <PointDivider value={children.length}/>}
    </TitleText>
    {!menuCollapsed && <MenuItemCollapseButton $isCollapsed={isCollapsed} size="small" type="text" icon={<IconArrowUp/>} onClick={collapseButtonClickHandler}/>}
    </div>;

    
    return <TitleContainer className={"top-title-container"}>
        <div className={"divider"}></div>
        {menuCollapsed ? <Tooltip title={title} placement={"right"}>{titletext}</Tooltip> : titletext}

        <div className="items-holder" ref={holderRef}>
            {
               children.map(entity => {
                    const k = (entity.entity as any).__typename + "_" + (entity.entity as any).id;
                    return <GroupMenuElement key={k} menuCollapsed={menuCollapsed} e={entity}
                                             onItemClick={onItemClick}/>
                })
            }
        </div>
    </TitleContainer>;
}


export const NoProjectsElement: React.FC<{ menuCollapsed: boolean }> = ({menuCollapsed}) => {
    const {token} = theme.useToken();
    const {t} = useTranslation();

    return <span style={{color: token.colors.font.white}}>{t('noProjects')}</span>;
}

interface MenuEntity {
    entity: Project | UsersUnion | User,
    children: MenuEntity[],
}

export const currentMenuIsSelected = (link: string) => {
    let res = false;
    if (!link) res = false;
    else if (link == "/" && window.location.pathname == "") res = true;
    else res = window.location.pathname.endsWith(link);

    return res;
}

interface LeftMenuItemsCollapseState{
    favorites: boolean,
    my: boolean,
    unions: boolean,
    personal: boolean
}


const leftMenuProjectsFilter =
  (p: Project, authInfo: AuthState, menuFilter: string): boolean => {
        return p.members.some(m => m.user.id == authInfo.user.id)
          &&
          p.title.toLowerCase().indexOf(menuFilter.toLowerCase()) > -1
    };

const onlyFavoriteFilter =
  (p: Project, authInfo: AuthState, menuFilter: string): boolean => (p.UserMarkers.isFavorite && leftMenuProjectsFilter(p, authInfo, menuFilter));

const projectSorter = (p1: Project, p2: Project): number => p1.usersUnionAsOwner?.title + "-" + p1.title > p1.usersUnionAsOwner?.title + "-" + p2.title ? 1 : -1;


export const LeftMenu: React.FC<LeftMenuProps> = ({collapsed, setCollapsed}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();
    const [itemsCollapseState, setItemsCollapse]=useState<LeftMenuItemsCollapseState>(
        JSON.parse(localStorage.getItem('leftMenu-items-collapse')?? '{}')?? 
        {favorites: false, my: false, unions: false, personal: false}
    ) //TODO: Идея с openKeys мне больше понравилась, переделаю

    const navigate = useNavigate();
    const authInfo = useReactiveVar(authState);
    const menuRef = useRef<any>(null)

    const [menuFilter, setMenuFilter] = useState<string>("");

    const [isMd, setMd] = useState<boolean>(false) //изменяется на true - false при изменении ширины экрана.

    useEffect(()=>{
        localStorage.setItem('leftMenu-items-collapse', JSON.stringify(itemsCollapseState))
    }, [itemsCollapseState])

    const allUnions = useReactiveVar(allUsersUnions);
    const allPrj = useReactiveVar(allProjects);

    // TODO: это надо сделать для мобилок обязательно
    // // Указание выбранного пункта меню в соответствии с адресом страницы
    // useEffect(() => {
    //     const selectedKeyFromPath = getActiveLeftMenuKey(leftItems, window.location.pathname)
    //     if (selectedKeyFromPath !== selectedKey)
    //         setSelectedKey(selectedKeyFromPath)
    // }, [selectedKey]);

    // const allKeys = leftItems.map(v => v.key);
    // if (!openedKeys)
    //     setOpenedKeys(allKeys);
    //
    //
    // const openedKeysResult = useMemo(() => {
    //     let res = openedKeys ?? [];
    //     // Чтобы эти элементы меню нельзя было свернуть
    //     if (!collapsed)
    //         alwaysOpened.find(vv => {
    //             if (res.indexOf(vv) < 0) res.push(vv)
    //         })
    //     return res;
    // }, [openedKeys, collapsed])

    const showCollapseButton = !isMobile;

    const favItems = useMemo(()=>{
        return allPrj.projects.filter(p=>onlyFavoriteFilter(p, authInfo, menuFilter)).sort(projectSorter).map(entity => ({
            entity,
            children: []
        } as MenuEntity))
    }, [allPrj.projects, authInfo, menuFilter])

    //TODO: проверить правильность кода в этом блоке
    const personalItems = useMemo(()=> {
        return allPrj.myProjects().filter(p => leftMenuProjectsFilter(p, authInfo, menuFilter)).sort(projectSorter).map(entity => ({
            entity,
            children: []
        } as MenuEntity))
    }, [allPrj.projects, authInfo, menuFilter])

    const uuItems = useMemo(()=> {
        return allUnions.unions.map(entity => ({
            entity,
            children: allPrj.projects
                .filter(p =>
                  // Если команда - владелец проекта
                  p.usersUnionAsOwner?.id == entity.id
                  // Или если текущий пользователь участвует в проекте от лица команды
                  || entity.projectInvites?.some(pi=>pi.project.id == p.id) && p.members?.some(m=>m.user.id == authInfo.user.id && m.asUnion?.id == entity.id)
                )
                .filter(p=>leftMenuProjectsFilter(p, authInfo, menuFilter)).sort(projectSorter).map(entity => ({
                    entity,
                    children: []
                } as MenuEntity))
        } as MenuEntity))
    }, [allUnions.unions, allPrj.projects, authInfo, menuFilter])

    const uItems = useMemo(()=> {
        return allPrj.otherUsers().map(entity => ({
            entity,
            children: allPrj.projects
                .filter(p => p.usersUnionAsOwner?.id == entity.id).filter(p=>leftMenuProjectsFilter(p, authInfo, menuFilter)).sort(projectSorter).map(entity => ({
                    entity,
                    children: []
                } as MenuEntity))
        } as MenuEntity))
    }, [allPrj.projects, authInfo, menuFilter])

    const onItemClick = (link: string) => {
        if (!link) return;

        navigate(link)
        if (isMobile)
            setCollapsed(true)
    }

    return <LeftSider
        $token={token}
        breakpoint="md"
        width={isMd ? "100%" : 300}
        collapsedWidth={isMd ? "0" : "88"}
        trigger={null}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onBreakpoint={(broken) => setMd(broken)}
    >
        {showCollapseButton && <LeftMenuCollapseButton collapsed={collapsed} isMd={isMd} setCollapsed={setCollapsed}/>}

        <div className="left-menu">
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                {!collapsed && <LeftMenuSearch size="middle" placeholder={t('searchLeftManu')}
		                                           onChange={(e) => {
                                setMenuFilter(e.currentTarget.value)
                            }}

                                           prefix={<SearchOutlined/>}/>}

                <LeftMenuButtons collapsed={collapsed} onClick={onItemClick}/>

                <CustomMenu $token={token} $collapsed={collapsed.toString()} $mobile={isMobile.toString()}
                            ref={menuRef}>
                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconFavourites/>}/>}
                    title={t("favoriteProjects")}
                    link={""}
                    children={favItems}
                    onItemClick={onItemClick}
                    isCollapsed={itemsCollapseState.favorites}
                    onCollapsedChange={(val)=>setItemsCollapse(prev=>({...prev, favorites: val }))}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconProjects/>}/>}
                    title={t("personalProjects")}
                    link={menuItems.personalProject}
                    children={personalItems}
                    onItemClick={onItemClick}
                    isCollapsed={itemsCollapseState.my}
                    onCollapsedChange={(val)=>setItemsCollapse(prev=>({...prev, my: val }))}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconTeam/>}/>}
                    title={t("usersUnion.teams")}
                    link={menuItems.uuProjects}
                    children={uuItems}
                    onItemClick={onItemClick}
                    isCollapsed={itemsCollapseState.unions}
                    onCollapsedChange={(val)=>setItemsCollapse(prev=>({...prev, unions: val }))}
                />

                <TopMenuElement
                    menuCollapsed={collapsed}
                    icon={<Icon size={"24"} icon={<IconMan/>}/>}
                    title={t("otherUsersProjects")}
                    link={menuItems.otherProjects}
                    children={uItems}
                    onItemClick={onItemClick}
                    isCollapsed={itemsCollapseState.personal}
                    onCollapsedChange={(val)=>setItemsCollapse(prev=>({...prev, personal: val }))}
                />
            </CustomMenu>
            </div>
        </div>

    </LeftSider>
}