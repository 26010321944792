import React, {useEffect, useMemo, useState} from 'react';
import {Outlet} from "react-router-dom";
import {Layout, theme} from 'antd';
import {useReactiveVar} from "@apollo/client";
import {authState} from "./routes/Auth/authContext";
import {
    Project,
    Tag,
    useAllPersonalTagsQuery,
    useProjectsListQuery,
    UsersUnion,
    UsersUnionMemberInvite,
    useUsersUnionsListQuery
} from "./generated-types";
import {showErrors, showLoading} from "./Components/helpers";
import {allUsersUnions} from "./subscriptions/allUsersUnions";
import {AllProjects, allProjects} from "./subscriptions/allProjects";
import TaskEditor from "./Components/Tasks/TaskEditor";
import {DndProvider} from 'react-dnd';
import {pollInterval} from "./ApolloClient";
import ProjectTasksFastList from "./Components/Project/ProjectTasksFastList";
import {allPersonalTags} from "./subscriptions/allPersonalTags";
import CookieConsent from 'react-cookie-consent';
import {useTranslation} from "react-i18next";
import {TouchBackend} from "react-dnd-touch-backend";
import PaymentWidgetEditor from "./Components/Payment/PaymentWidgetModal";
import AddSlotsModalEditor from "./Components/Payment/AddSlotsModal";
import {BlockedInfo} from "./Components/Payment/BlockedInfo";
import TariffTableModalEditor from "./Components/Payment/ModalTariffsTable";
import {CommonDragLayer} from "./Components/CommonDragLayer";
import ProjectTransfersList from "./Components/Project/ProjectTransfersList";
import {TaskNewModal} from "./Components/Tasks/TaskNew";
import {HTML5Backend} from 'react-dnd-html5-backend';
import {isMobile} from 'react-device-detect';
import {TopMenu} from "./Components/TopMenu";
import {LeftMenu} from "./Components/AntCastomComponents/LeftMenu/LeftMenu";
import {LeftMenuUnauthorized} from "./Components/AntCastomComponents/LeftMenu/LeftMenuUnauthorized";
import UsersUnionsInvitesList from "./Components/UsersUnion/UsersUnionsInvitesList";
import ProjectInvitesList from "./Components/Project/ProjectInvitesList";
import {TopAlerts} from "./Components/Project/TopAlertsList";
import { Link } from './Components/Link';
import UsersUnionsTransfersList from './Components/UsersUnion/UsersUnionsTransfersList';

const {Content} = Layout;

const App: React.FC = () => {
    const {t} = useTranslation();
    const [contentMargin, setContentMargin] = useState<number>(0); // marginLeft меняется динамически, так как позиция боковой панели фиксирована и выведена и потока.
    const [value, setValue] = useState({width: 0, height: 0});
    const resizeHandler = () => setValue((prev) => ({...prev, width: window.innerWidth, height: window.innerHeight}));

    //Логика своачивания боковой панели
    const [collapsed, setCollapsed] = useState<boolean>(false);

    const {
        token: {colorBgContainer, colorWhite, colors},
    } = theme.useToken();

    const authInfo = useReactiveVar(authState);

    const isAuthorized = useMemo(() => authInfo.user != null, [authInfo]);

    const skip = useMemo(() => !isAuthorized, [authInfo]);

    const pollIntervalTime = useMemo(
        () => (skip ? undefined : pollInterval),
        [skip]
    );

    // TODO: переделать на подписки в будущем?
    const projectsQ = useProjectsListQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allProjects(new AllProjects(data.projects as Project[]));
        },
        pollInterval: (pollIntervalTime ?? 60000) + 5000 // TODO: переделать на подписку
    })

    const unionsQ = useUsersUnionsListQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allUsersUnions({
                unions: data.usersUnions as UsersUnion[] ?? [],
                invites: data.usersUnionsInvites as UsersUnionMemberInvite[] ?? []
            });
        },
        pollInterval: (pollIntervalTime ?? 60000) + 1000 // TODO: переделать на подписку
    })

    const allTagsQ = useAllPersonalTagsQuery({
        skip: skip,
        // fetchPolicy: 'network-only',
        onCompleted: (data) => {
            allPersonalTags({
                tags: data.allPersonalTags as Tag[] ?? []
            });
        },
        pollInterval: pollIntervalTime ? pollIntervalTime * 10 : undefined // TODO: переделать на подписку
    })

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    useEffect(() => {
        const height = `${window.innerHeight * 0.01}px`;
        document.documentElement.style.setProperty('--vh', height);
    }, [value.height]);

    useEffect(() => {
        if (!isAuthorized) {
            unionsQ.stopPolling();
            projectsQ.stopPolling();
            allTagsQ.stopPolling();
        } else {
            unionsQ.startPolling(pollInterval);
            projectsQ.startPolling(pollInterval);
            allTagsQ.startPolling(pollInterval);
        }
    }, [authInfo]);


    // TODO: заготовка для смены темы светлая/темная
    // const changeTheme = (value: boolean) => {
    // 	if (value)
    // 		UiTheme(UiAlgorithm.dark);
    // 	else
    // 		UiTheme(UiAlgorithm.light);
    // };
    // {/*<Switch onChange={changeTheme} />*/}
    const backend=isMobile? TouchBackend : HTML5Backend
    const options=isMobile? {delayTouchStart: 500} : undefined

    return (
        <DndProvider backend={backend} options={options}>
            <TaskEditor/>
            <ProjectTasksFastList/>
            <CommonDragLayer />
            <Layout>
                <TopMenu collapsed={collapsed} setCollapsed={setCollapsed}/>

                <Layout style={{height: "calc(100vh - 56px)"}}>
                    {isAuthorized
                        ? <LeftMenu contentMargin={contentMargin} setContentMargin={setContentMargin}
                                    collapsed={collapsed} setCollapsed={setCollapsed}
                        />
                        : <LeftMenuUnauthorized contentMargin={contentMargin} setContentMargin={setContentMargin}
                                                collapsed={collapsed} setCollapsed={setCollapsed}
                        />
                    }

                    <Layout style={{padding: '0', marginLeft: contentMargin, transition: "all 0.3s ease"}}>
                        {isAuthorized ? <BlockedInfo/> : null}
                        {isAuthorized && showErrors([projectsQ, unionsQ])}
                        {isAuthorized && showLoading([projectsQ, unionsQ])}

                        {isAuthorized && <TopAlerts>
                                <UsersUnionsTransfersList/>
			                    <ProjectTransfersList/>
			                    <UsersUnionsInvitesList/>
			                    <ProjectInvitesList/>
		                    </TopAlerts>}
                        <Content
                            style={{
                                overflow: 'auto',
                                maxHeight: 'calc(100vh - 56px)',
                                height: 'calc(100vh - 56px)',
                                display: "flex", flexDirection: "column",
                                background: colors.ui.bgLight,
                            }}
                        >
                            <Outlet/>
                        </Content>
                        <CookieConsent
                            overlay={true}
                            contentStyle={{textAlign: "right"}}
                            buttonText={t('cookieConsent.acceptButton')}
                        >{t('cookieConsent.mainText')}<br/>
                            <Link to={"/PersonalDataAcceptance"}>{t('cookieConsent.linkText')}</Link>
                        </CookieConsent>
                        <AddSlotsModalEditor/>
                        <PaymentWidgetEditor/>
                        <TariffTableModalEditor/>
                        <TaskNewModal/>
                    </Layout>
                </Layout>
            </Layout>
        </DndProvider>
    );
};

export default App;